<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- 404 -->
    <div class="container space-3 space-bottom-2 space-bottom-lg-3 overflow-hidden">
      <div class="w-lg-80 mx-lg-auto text-center">
        <img class="img-fluid w-100 w-md-50" src="@/assets/img/images/404.png" alt="Image Description">
        <div class="my-6">
          <h2 class="mb-4">Oops, page not found</h2>
          <!-- <p>Unfortunately the contents may no longer exist or the address may have been typed incorrectly.</p> -->
          <p>Thank you for visiting the FabriXAPI powered API Portal. It appears that you have landed on this page in error. Kindly verify the URL and try again.</p>

        </div>
        <!-- <a href="/" class="btn btn-primary"> -->
        <router-link class="btn btn-primary" :to="{ path: '/' }">
          Back to FabriXAPI
        </router-link>
        <!-- </a> -->
      </div>
    </div>
    <!-- End 404 -->

  </main>
</template>

<script>
import 'bootstrap'
export default {
  name: 'Error404',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: '%s | FabriXAPI',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/error' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'FabriXAPI' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/error' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
